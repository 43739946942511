.image-gallery {
    width: 70%;
    height: auto;
    display: flex;
    justify-content: center;
  }
  
  .image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
  }
  
  .fullscreen .image-gallery-slide img {
    max-height: 100vh;
  }
  
  @media (max-width: 768px) {
    .image-gallery {
      width: 100%;
    }
  }
  