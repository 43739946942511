::-webkit-scrollbar {
  width: 8px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #689df6;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3b78dc;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-button {
  display: none;
}
